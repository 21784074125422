// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-egen-webbutvecklare-js": () => import("./../../../src/pages/egen-webbutvecklare.js" /* webpackChunkName: "component---src-pages-egen-webbutvecklare-js" */),
  "component---src-pages-gatsby-js": () => import("./../../../src/pages/gatsby.js" /* webpackChunkName: "component---src-pages-gatsby-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integritetspolicy-js": () => import("./../../../src/pages/integritetspolicy.js" /* webpackChunkName: "component---src-pages-integritetspolicy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-mig-js": () => import("./../../../src/pages/om-mig.js" /* webpackChunkName: "component---src-pages-om-mig-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-referenser-js": () => import("./../../../src/pages/referenser.js" /* webpackChunkName: "component---src-pages-referenser-js" */),
  "component---src-pages-tjanster-js": () => import("./../../../src/pages/tjanster.js" /* webpackChunkName: "component---src-pages-tjanster-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

